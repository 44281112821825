<template>
  <div class="imgItem" :style="styleSizeName" :class="className">
    <el-table :data="dataChart" height="900"  stripe='true'  :cell-style="cellStyle" size="medium"  :row-class-name="tableRowClassName" :header-cell-style="tableHeaderCellStyle">
      <el-table-column prop="code"   align="center" label="机台编号"> </el-table-column>
      <el-table-column prop="value" align="center"  label="白班">
        <el-table-column prop="value1" min-height="80"  align="center" label="开机时间(h)"></el-table-column>
        <el-table-column prop="value2"  min-height="80"   align="center" label="停机时间(h)"></el-table-column>
        <el-table-column prop="value3"  min-height="80"  align="center" label="开机率  (%)"></el-table-column>
        <el-table-column prop="value4"  min-height="80"  align="center" label="平均速度(转/分)"></el-table-column>
        <el-table-column prop="value5"  min-height="80"  align="center" label="当前产量(km)"></el-table-column>
        <el-table-column prop="value6"  min-height="80"  align="center" label="计划产量(km)"></el-table-column>
      </el-table-column>
      <el-table-column align="center" prop="valuey"  label="夜班">
        <el-table-column prop="value11"  align="center" label="开机时间(h)"></el-table-column>
        <el-table-column prop="value12"  align="center" label="停机时间(h)"></el-table-column>
        <el-table-column prop="value13"  align="center" label="开机率  (%)"></el-table-column>
        <el-table-column prop="value14"  align="center" label="平均速度(转/分)"></el-table-column>
        <el-table-column prop="value15"  align="center" label="当前产量(km)"></el-table-column>
        <el-table-column prop="value16"  align="center" label="计划产量(km)"></el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "machineList",
  data() {
    return {
    };
  },
  components: {},
  props: {
    component: Object,
  },

  watch: {
  },
  computed: {
    defaultOption() {
      return {
      };
    },
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      console.log('rowIndex', rowIndex)
        if (rowIndex % 2 == 0) {
          console.log('rowIndex222', rowIndex)
          return 'selfwarning-row';
        }else {
          console.log('rowIndex333', rowIndex)
          return 'selfsuccess-row';
        }
        return '';
      },
      tableHeaderCellStyle () {
        // return 'background: rgb(15,53,81);color:rgb(150,170,210);font-weight: bold;font-size: 24px;border: 0 !important;'
        return 'background: rgb(15,53,81) !important;color:rgb(150,170,210);height:80px;font-weight: bold;font-size: 26px;border: 0px !important;'
      },
      cellStyle(row) {
        console.log('row', row)
        // "border-right": '4px solid #09f'
        
        let  item =  {"color":"#fff !important","font-size": '24px',"font-weight": 'bold',"height":"80px"}
        if (row.rowIndex % 2 == 0) {
          item['background-color'] = '#0b1632 !important'
        }else {
          item['background-color'] = '#14225a !important'
        }
        let data_value15_all = parseFloat(row.row.value15 ) + parseFloat(row.row.value5)
        let data_value16_all = parseFloat(row.row.value16 ) + parseFloat(row.row.value6)
        let data_reslut = data_value16_all - data_value15_all
         if ( data_value15_all== 0 &&  data_value16_all== 0){
          item["color"] = "rgba(123, 121, 120, 1)"
         }else if((data_reslut < 3 || data_reslut == 3) && (row.column.property == 'code' || row.column.property == 'value3' || row.column.property == 'value13' || row.column.property == 'value6' || row.column.property == 'value16') ){
  
          item["color"] = "rgba(144, 238, 144, 1)"

         } else if(data_reslut > 3  && (row.column.property == 'code' || row.column.property == 'value3' || row.column.property == 'value13' || row.column.property == 'value6' || row.column.property == 'value16') ){
          
          item["color"] = "rgba(255, 69, 0, 1)"
         } 

         if( row.column.property == 'code' || row.column.property == 'value6'){
          item["border-right"] = '4px solid #1296db'
          
         }
         return item
        
      },
  },
};
</script>
<style  lang="scss" scoped>
//去边框
.imgItem ::v-deep  .el-table--border {
    border: none;
  }
  .imgItem ::v-deep .el-table--border::after {
    width: 0px;
  }
  .imgItem .el-table::before {//去除底部白线
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
  }
  .imgItem ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
  }
  // 滚动条的滑块
  .imgItem ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: transparent!important;
    border-radius: 3px;
  }
  .imgItem ::v-deep  .el-table .cell{
    line-height:30px;
  }
.imgItem ::v-deep .el-table,
.imgItem ::v-deep .el-table__expanded-cell {
  background-color: rgb(30,35,95);
  color: #606266;
  border: 0px ;
}
/* 表格内背景颜色 */
.imgItem ::v-deep .el-table th,
.imgItem ::v-deep .el-table tr,
.imgItem ::v-deep .el-table td {
  background-color: rgb(30,35,95) !important;
  //表格字体颜色
  color: #606266;
  border: 0px;
}
.imgItem ::v-deep .el-table th.el-table__cell{


}
.imgItem ::v-deep .el-table--border th.el-table__cell.gutter:last-of-type {
    border-bottom: 1px solid rgb(30,35,95);}

// .imgItem ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
// 	background: #142253 !important;
//   color: #fff;
// }

// /* 非斑马纹颜色 */
// .imgItem ::v-deep.el-table tr {
// 	background: #0c1736 !important;
// }
 
// /* 斑马纹颜色定义完之后会显示自带的边框颜色，这里要重置 */
// .imgItem ::v-deep.el-table td,
// .building-top .el-table th.is-leaf {
// 	border: none;
// 	color: white;
// }
 
// /* 这里是滑过斑马纹滑过时的颜色 */
// .imgItem ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
// 	background-color: #142253;
// }

.imgItem { 

   .selfwarning-row {
    background: #e50b5b !important;;
   }

   .selfsuccess-row {
    background: #2681ff !important; 
   }

  width: 100%;
  height: 100%;
  overflow :scroll;

}
</style>

